/** @jsx jsx */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { jsx, Box } from 'theme-ui'
const Wiper = ({ in: _in, color, children, ...props }) => {
  const [isIn, setIsIn] = useState(false)
  useEffect(() => {
    requestAnimationFrame(() => setIsIn(_in))
  }, [_in])
  return (
    <Box
      sx={{
        display: 'inline-block',
        position: 'relative',
        clipPath: isIn ? 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' : 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)',
        transition: '500ms all ease-in-out',
        '&::after': !!color && {
          display: 'block',
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          backgroundColor: color,
          transition: '500ms transform ease-in-out 250ms',
          transform: isIn ? 'translateX(calc(100% + 10px))' : 'translateX(0)'
        }
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

Wiper.propTypes = {
  children: PropTypes.node.isRequired,
  in: PropTypes.bool.isRequired,
  color: PropTypes.any
}

export default Wiper
