/** @jsx jsx */
import React, {
  memo,
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
  useContext,
  useRef
} from 'react'
import PropTypes from 'prop-types'
import { jsx, Flex, Box, Heading, Card, Paragraph, Link, Button, Themed } from 'theme-ui'
import {
  motion,
  useAnimation,
  useReducedMotion,
  useMotionValue,
  useTransform,
  useViewportScroll,
  useSpring
} from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { FaDiscord, FaTwitter, FaChevronDown } from 'react-icons/fa'
import { Helmet } from 'react-helmet'
import moment from 'moment-timezone'
import theme from '../theme'
import sleep from '../util/sleep'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Logo from '../components/logo'
import Wiper from '../components/wiper'

const userTz = moment.tz.guess()
const startTime = moment('2021-07-09T19:00:00.000Z').tz(userTz)
const endTime = moment('2021-07-12T19:00:00.000Z').tz(userTz)

function useDelayedUpdate (value, delay) {
  const [returned, setReturned] = useState(value)
  useEffect(() => {
    function update () {
      setReturned(value)
    }
    const timeout = setTimeout(update, delay)
    return () => clearTimeout(timeout)
  }, [value, delay])
  return returned
}

function useStaggeredUpdate (value, delay, n) {
  const [returned, setReturned] = useState(Array(n).fill(value))
  useEffect(() => {
    let i = 0
    function update () {
      const capturedI = i
      setReturned(list => {
        list = [...list]
        list[capturedI] = value
        return list
      })
      i += 1
      if (i < n) {
        setTimeout(update, delay)
      }
    }
    update()
  }, [value, delay, n])
  return returned
}

const pageTransition = {
  delay: 2,
  duration: 2,
  ease: [0.5, 0, 0.5, 1]
}

const mastheadTransition = {
  delay: pageTransition.delay + 0.5,
  duration: 2,
  ease: pageTransition.ease
}

const pageVariants = {
  mastheadWrapper: {
    initial: {
      y: 'calc(50vh - 50%)'
    },
    shown: {
      y: 'calc(20vh - 50%)',
      transition: mastheadTransition
    }
  },
  logo: {
    initial: {
      width: '50vw'
    },
    shown: {
      width: '7rem',
      transition: pageTransition
    }
  },
  wordCtf: {
    initial: {
      width: '0'
    },
    shown: {
      width: 'auto',
      transition: pageTransition
    }
  },
  wordCtfInner: {
    initial: {
      x: '-150%'
    },
    shown: {
      x: '0',
      transition: pageTransition
    }
  },
  hider: {
    initial: {
      backgroundColor: theme.colors.background
    },
    shown: {
      backgroundColor: 'rgba(0,0,0,0)',
      transition: {
        ...mastheadTransition,
        delay: mastheadTransition.delay + 0.5
      }
    }
  }
}

const App = () => {
  const shouldReduceMotion = useReducedMotion()
  const [shouldAnimate, setShouldAnimate] = useState(null)
  useLayoutEffect(() => {
    if (shouldReduceMotion) {
      setShouldAnimate(false)
      return
    }
    const root = document.documentElement
    if (root.scrollTop !== 0) {
      setShouldAnimate(false)
      return
    }
    setShouldAnimate(true)
    // We only want to update shouldAnimate on mount; we don't care if anything
    // changes after mount, since the animation already played.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const controls = useAnimation()

  const [isIn, setIsIn] = useState(false)
  const onIn = useCallback(() => setIsIn(true), [])

  const [hasScrollbar, setHasScrollbar] = useState()
  useLayoutEffect(() => {
    const root = document.documentElement
    setHasScrollbar(root.scrollHeight > root.clientHeight)
  }, [])
  const [isScrollLocked, setIsScrollLocked] = useState(true)
  useEffect(() => {
    if (shouldAnimate === false) {
      setIsScrollLocked(false)
      setIsIn(true)
    }
  }, [shouldAnimate])
  useLayoutEffect(() => {
    if (hasScrollbar === undefined) return
    const root = document.documentElement
    const body = document.body
    if (!hasScrollbar) {
      root.style.overflowY = 'auto'
    } else {
      if (isScrollLocked) {
        root.style.overflowY = 'hidden'
        body.style.overflowY = 'scroll'
      } else {
        root.style.overflowY = 'auto'
        body.style.overflowY = 'hidden'
      }
    }
  }, [isScrollLocked, hasScrollbar])
  useEffect(() => {
    const action = async () => {
      await sleep(2500)
      setIsScrollLocked(false)
    }
    action()
  }, [])
  useEffect(() => {
    if (shouldAnimate === null) return
    if (shouldAnimate) {
      controls.start('shown')
    } else {
      controls.set('shown')
    }
  }, [controls, shouldAnimate])

  const [mastheadRef, isMastheadInView] = useInView()

  const scrollBtnRef = useRef()
  const onScrollBtnClick = useCallback(() => {
    scrollBtnRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }, [])

  const isContentIn = useDelayedUpdate(!isScrollLocked, 1500)
  const wipersIn = useStaggeredUpdate(isContentIn, 100, 4)

  return (
    <motion.div
      sx={{
        position: 'relative',
        minHeight: '100vh'
      }}
      animate={controls}
      initial="initial"
      onAnimationComplete={onIn}
    >
      <Box
        sx={{
          height: '75vh',
          backgroundColor: 'accent',
          clipPath: 'polygon(0% 0%, 100% 0%, 100% 65vh, 50% 75vh, 0% 65vh)',
          position: 'relative'
        }}
        ref={mastheadRef}
      >
        <Flex
          sx={{
            position: 'absolute',
            bottom: '12.5vh',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: '29vh'
          }}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: 'heading',
              fontWeight: 'heading',
              fontSize: 4,
              userSelect: 'all'
            }}
          >
            <Wiper in={wipersIn[0]} color="background">
              {startTime.format('L LT z')}
            </Wiper>
            <Wiper in={wipersIn[1]} color="background">
                to
            </Wiper>
            <Wiper in={wipersIn[2]} color="background">
              {endTime.format('L LT z')}
            </Wiper>
          </Flex>
          <motion.div
            inherit={false}
            initial="hidden"
            animate={wipersIn[3] ? 'visible' : 'hidden'}
            variants={{
              hidden: {
                opacity: 0,
                y: '0.5rem'
              },
              visible: {
                opacity: 1,
                y: 0
              }
            }}
            transition={{
              ease: 'easeOut',
              duration: 0.5,
              delay: 0.2
            }}
          >
            <Button
              as='a'
              sx={{
                boxShadow: 'rgba(250, 250, 250, 0.6) 0px 0px 1rem 0px',
                padding: 3,
                fontSize: 3,
                transition: 'box-shadow ease-in-out 0.2s, transform ease-in-out 0.2s',
                backgroundColor: 'background',
                borderRadius: 2,
                ':hover': {
                  boxShadow: 'rgba(250, 250, 250, 0.6) 0px 0px 1.1rem 0px',
                  transform: 'scale(1.1)' // blame ginkoid
                },
                fontFamily: 'heading',
                fontWeight: 'heading'
              }}
              href='https://2021.redpwn.net'
              target='_blank'
              rel='noopener'
            >
              <span>Play 2021</span>
              <svg viewBox='4 4 16 16' sx={{ height: '1em', top: '0.125em', position: 'relative', marginLeft: '10px' }}>
                <path fill='#ffffff' d='M16.01 11H4v2h12.01v3L20 12l-3.99-4z' />
              </svg>
            </Button>
          </motion.div>
        </Flex>
        <motion.div
          sx={{
            position: 'absolute',
            bottom: '2vh',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          animate={{ opacity: isContentIn ? 1 : 0 }}
          transition={{
            duration: 2,
            ease: 'easeOut'
          }}
        >
          <motion.div
            sx={{ p: 2, lineHeight: '1' }}
            ref={scrollBtnRef}
            onClick={onScrollBtnClick}
            animate={{ y: '0rem' }}
            initial={{ y: '-0.5rem' }}
            transition={{
              yoyo: Infinity,
              duration: 2,
              ease: 'easeInOut'
            }}
          >
            <FaChevronDown />
          </motion.div>
        </motion.div>
      </Box>
      <Content in={isContentIn} />
      {!isIn && (
        <motion.div
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}
          variants={pageVariants.hider}
        />
      )}
      <motion.div
        sx={{
          position: 'absolute',
          top: '0',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        variants={pageVariants.mastheadWrapper}
      >
        <motion.div variants={pageVariants.logo}>
          <Logo
            animate={shouldAnimate}
            sx={{ width: '100%', zIndex: 1000 }}
            layoutId="logo"
          />
        </motion.div>
        <motion.div
          sx={{
            clipPath: 'polygon(-2rem 0%, 100% 0%, 100% 100%, -2rem 100%)',
            zIndex: 500
          }}
          variants={pageVariants.wordCtf}
        >
          <Heading
            m={0} p={0}
            sx={{ fontSize: '4rem' }}>
            <motion.div
              sx={{ display: 'inline-block', paddingLeft: '2rem' }}
              variants={pageVariants.wordCtfInner}
            >
              CTF
            </motion.div>
          </Heading>
        </motion.div>
      </motion.div>
      { isContentIn && <ParallaxBg /> }
      { isMastheadInView && (
        <Helmet>
          <meta name="theme-color" content={theme.colors.accent} />
        </Helmet>
      )}
    </motion.div>
  )
}

const ParallaxBg = () => {
  const _inAnim = useMotionValue(0)
  const inAnim = useSpring(_inAnim, { stiffness: 10, damping: 50 })
  useEffect(() => {
    setTimeout(() => {
      _inAnim.set(1)
    }, 0)
  }, [_inAnim])
  const scale = useTransform(inAnim, [0, 1], [1.1, 1])
  const opacity = useTransform(inAnim, [0, 1], [0, 1])
  const { scrollY } = useViewportScroll()
  const _parallaxY = useTransform(scrollY, y => (y / 3))
  const parallaxY = useSpring(_parallaxY,
    {
      stiffness: 900,
      damping: 100
    }
  )
  const bgY = parallaxY

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        zIndex: -100
      }}
    >
      <motion.div
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '200%',
          background: 'radial-gradient(#444, #444 0.07rem, rgba(0,0,0,0) 0.071rem) repeat',
          backgroundSize: '2rem 2rem',
          transformOrigin: '50% 0%'
        }}
        style={{ y: bgY, scale, opacity }}
        g>
      </motion.div>
    </Box>
  )
}

const ContentInContext = React.createContext(true)

const ContentCard = ({ children, ...props }) => {
  const [ref, inView] = useInView({
    rootMargin: '-50px 0px',
    triggerOnce: true
  })
  const contentIsIn = useContext(ContentInContext)
  const isIn = inView && contentIsIn

  return (
    <Card
      ref={ref}
      mb={4}
      sx={{
        transition: '500ms all ease-out',
        opacity: isIn ? 1 : 0,
        transform: isIn ? 'translateY(0)' : 'translateY(1rem)',
        '&:last-child': {
          mb: '0'
        }
      }}
      {...props}
    >
      {children}
    </Card>
  )
}

ContentCard.propTypes = {
  children: PropTypes.node.isRequired
}

let Content = ({ in: isIn }) => (
  <ContentInContext.Provider value={isIn}>
    <Box
      p={[4, 5]}
      mt={[2, 0]}
      sx={{
        maxWidth: '60rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        minHeight: '100vh',
        zIndex: 0
      }}
    >
      <ContentCard>
        <Heading>What is redpwnCTF?</Heading>
        <Paragraph>
          redpwnCTF is a cybersecurity competition hosted by the
          {' '}
          <Link href="https://ctftime.org/team/59759">redpwn CTF team</Link>.
          It&rsquo;s online, jeopardy-style, and includes a wide variety of
          computer science and cybersecurity challenges. Compete in challenge
          categories such as binary exploitation, reverse engineering,
          cryptography, and web to earn points. Please join our
          {' '}
          <Link href="https://pwn.red/discord">Discord</Link> to learn more and
          get notified as the competition date nears!
        </Paragraph>
      </ContentCard>
      <ContentCard>
        <Heading>What if I&rsquo;m new?</Heading>
        <Paragraph>
          Don&rsquo;t worry, we&rsquo;ve intentionally written multiple
          challenges in every category geared toward participants with
          little or no programming experience. We understand it can be scary
          trying something new, but we hope redpwnCTF will give you a gentle
          introduction into the wonderful world of cybersecurity!
        </Paragraph>
        <Paragraph>
          You can also check out our challenges from previous years on GitHub:
        </Paragraph>
        <Themed.ul>
          {['2021', '2020', '2019'].map(year => (
            <Themed.li key={year}>
              <Link
                href={`https://github.com/redpwn/redpwnctf-${year}-challenges`}
                target="_blank"
                rel="noopener"
              >
                redpwnCTF {year}
              </Link>
            </Themed.li>
          ))}
        </Themed.ul>
      </ContentCard>
      <ContentCard>
        <Heading>Prizes</Heading>
        <Paragraph>
          Thanks to our generous sponsors, redpwnCTF 2021 has distributed the
          following prizes among the top three teams of each division:
        </Paragraph>
        <Themed.ul>
          <Themed.li>$2,000 of Digital Ocean credits</Themed.li>
          <Themed.li>$1,000 USD thanks to Trail of Bits</Themed.li>
          <Themed.li>$1,000 USD thanks to GitHub Security Lab</Themed.li>
          <Themed.li>HackerOne swag including hoodies, t-shirts, private program invites, and more</Themed.li>
        </Themed.ul>
        <Paragraph>There are three divisions:</Paragraph>
        <Themed.ul>
          <Themed.li>a prize-eligible <Themed.b>high school division</Themed.b> for incoming, US-based high school seniors and younger only</Themed.li>
          <Themed.li>a prize-eligible <Themed.b>college division</Themed.b> open to incoming, US-based undergraduate college seniors and younger only</Themed.li>
          <Themed.li>an <Themed.b>open division</Themed.b> for remaining competitors. Note that unlike previous years, this year we will be distributing prizes to the open division as well</Themed.li>
        </Themed.ul>
      </ContentCard>
      <ContentCard>
        <Heading>Sponsors</Heading>
        <Paragraph>Thank you to our sponsors for making this event possible!</Paragraph>
        <Themed.ul>
          <Themed.li><Link href="https://www.trailofbits.com/">Trail of Bits</Link></Themed.li>
          <Themed.li><Link href="https://securitylab.github.com/">GitHub Security Lab</Link></Themed.li>
          <Themed.li><Link href="https://do.co/studenthackathon">Digital Ocean</Link></Themed.li>
          <Themed.li><Link href="https://hackerone.com/">HackerOne</Link></Themed.li>
          <Themed.li>Infrastructure sponsored by <Link href="https://g.co/cloud">Google Cloud</Link></Themed.li>
        </Themed.ul>
        <Paragraph>
          If you would like to hear more about our sponsorship packages,
          please don&rsquo;t hesitate to
          {' '}
          <Link href="mailto:contact@redpwn.net?Subject=redpwnCTF%202022" target="_top">
            send us an email!
          </Link>
        </Paragraph>
      </ContentCard>
      <ContentCard>
        <Box
          sx={{
            display: 'grid',
            gridTemplate: '"auto auto"',
            gridGap: '1rem',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Link href="https://discord.gg/25fu2Xd"><FaDiscord size="4em" /></Link>
          <Link href="https://twitter.com/redpwnCTF"><FaTwitter size="4em" /></Link>
        </Box>
        <Flex
          mt={2}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            color: 'muted',
            fontSize: 0
          }}
        >
          <Link href="mailto:contact@redpwn.net?Subject=redpwnCTF%202021" target="_top">
            contact@redpwn.net
          </Link>
        </Flex>
      </ContentCard>
    </Box>
  </ContentInContext.Provider>
)

Content = memo(Content)

Content.propTypes = {
  in: PropTypes.bool.isRequired
}

const IndexPage = () => (
  <>
    <SEO />
    <Layout>
      <App />
    </Layout>
  </>
)

export default IndexPage
