/** @jsx jsx */
import React, { useEffect, useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { jsx, Flex, Box, Heading } from 'theme-ui'
import { motion, useAnimation } from 'framer-motion'
import theme from '../theme'

const easeVeryOut = [0, 0, 0.2, 1]

const textTransition = {
  duration: 0.8,
  ease: easeVeryOut,
  delay: 0.5
}

const logoVariants = {
  root: {
    hidden: {
      boxShadow: '0 0 1rem 0 rgba(250,250,250,0.0)',
      // border: '0.2em rgba(181,25,29,0) solid',
      transition: {
        duration: 1
      }
    },
    boxShadow: {
      boxShadow: '0 0 1rem 0 rgba(250,250,250,0.8)',
      // border: '0.2em rgba(181,25,29,1) solid',
      transition: {
        duration: 2,
        delay: 1.2,
        ease: 'easeInOut'
      }
    }
  },
  hex: {
    hidden: {
      fill: theme.colors.background,
      transition: {
        duration: 1
      }
    },
    stroke: {
      transition: {}
    },
    visible: {
      fill: 'rgba(14,63,74, 1)',
      transition: {
        duration: 1
      }
    }
  },
  pawn: {
    hidden: {
      fill: theme.colors.background,
      transition: {
        duration: 1
      }
    },
    stroke: {
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.4
      }
    },
    visible: {
      fill: 'rgba(188,34,38, 1)',
      transition: {
        duration: 1
      }
    }
  },
  stroke: {
    hidden: {
      pathLength: 0,
      stroke: 'rgba(0,0,0,0)',
      strokeWidth: 5,
      transition: {
        duration: 1
      }
    },
    stroke: {
      pathLength: 1,
      stroke: ['rgba(0,0,0,0)', theme.colors.text],
      transition: {
        duration: 1,
        times: [0, 0.01]
      }
    },
    visible: {
      stroke: 'rgba(0,0,0,0)',
      transition: {
        duration: 1
      }
    }
  },
  textL: {
    hidden: {
      x: '100%',
      opacity: 1
    },
    stroke: {
      x: '0',
      transition: textTransition
    },
    visible: {
      opacity: 0,
      transition: {
        duration: 0.8
      },
      transitionEnd: {
        display: 'none'
      }
    }
  },
  textR: {
    hidden: {
      x: '-100%',
      opacity: 1
    },
    stroke: {
      x: '0',
      transition: textTransition
    },
    visible: {
      opacity: 0,
      transition: {
        duration: 0.8
      },
      transitionEnd: {
        display: 'none'
      }
    }
  }
}

const Logo = ({ animate, ...props }) => {
  const [hasMounted, setHasMounted] = useState(false)
  const controls = useAnimation()
  useEffect(() => {
    const action = async () => {
      if (hasMounted === false) return
      if (animate === null) return
      if (animate) {
        controls.start('boxShadow')
        await controls.start('stroke')
        await controls.start('visible')
      } else {
        controls.set('boxShadow')
        controls.set('visible')
      }
    }
    action()
  }, [controls, animate, hasMounted])
  useLayoutEffect(() => setHasMounted(true), [])
  if (!hasMounted) return null
  return (
    <motion.div
      sx={{
        display: 'inline-block',
        borderRadius: '1em',
        position: 'relative',
        backgroundColor: 'background' // it appears the floor here is made out of floor
      }}
      variants={logoVariants.root}
      animate={controls}
      initial="hidden"
      {...props}
    >
      <Box sx={{ paddingTop: '100%' }} />
      <Flex
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <motion.svg
          viewBox="0 0 694.78 694.17"
          sx={{ height: '80%', width: '80%' }}
        >
          <motion.g transform="translate(-64.57)" variants={logoVariants.hex}>
            <motion.path
              id="polyline8"
              d="m190.97 610.42 314.6 83.75 253.78-254.61-84.21-312.23z"
              variants={logoVariants.stroke}
            />
            <motion.path
              id="polyline10"
              d="M 633.06,83.77 318.35,0 64.57,254.61 149.19,568.35z"
              variants={logoVariants.stroke}
            />
          </motion.g>
          <motion.g variants={logoVariants.pawn} transform="translate(-64.57)">
            <motion.path
              transform="translate(-88.04,-152.92)"
              d="m584.31 374h-32.88a74 74 0 0021.57-52.47c0-40.79-32.7-73.86-73-73.86s-73 33.07-73 73.86a74 74 0 0021.57 52.47h-32.88a19.85 19.85 0 00-19.74 20v2.95a19.85 19.85 0 0019.74 19.95h168.62a19.85 19.85 0 0019.74-19.95v-2.95a19.85 19.85 0 00-19.74-20z"
              variants={logoVariants.stroke}
            />
            <motion.path
              d="m311.31 471.47h197.49c8.7477 0 15.79 7.0423 15.79 15.79v17.36c0 8.7477-7.0423 15.79-15.79 15.79h-197.49c-8.7477 0-15.79-7.0423-15.79-15.79v-17.36c0-8.7477 7.0423-15.79 15.79-15.79z"
              variants={logoVariants.stroke}
            />
            <motion.path
              d="m279.7 535.78h264.52c9.839 0 17.76 7.921 17.76 17.76v28.1c0 9.839-7.921 17.76-17.76 17.76h-264.52c-9.839 0-17.76-7.921-17.76-17.76v-28.1c0-9.839 7.921-17.76 17.76-17.76z"
              stroke-dasharray="0,10000000,0"
              variants={logoVariants.stroke}
            />
            <motion.path
              transform="translate(-88.04,-152.92)"
              d="m407.57 609c54.85-98.7 35.49-176.82 35.49-176.82h113.88s-19.36 78.13 35.49 176.82z"
              variants={logoVariants.stroke}
            />
          </motion.g>
        </motion.svg>
      </Flex>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Heading
          m={0} p={0}
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            clipPath: 'polygon(-1000% 0%, 100% 0%, 100% 100%, -1000% 100%)',
            fontSize: '3rem',
            flex: '1 0 0'
          }}
        >
          <motion.div
            sx={{ display: 'inline-block', right: 0, position: 'absolute' }}
            variants={logoVariants.textL}
          >
            RED
          </motion.div>
        </Heading>
        <Box sx={{ width: '20%' }} />
        <Heading
          m={0} p={0}
          sx={{
            clipPath: 'polygon(0% 0%, 1000% 0%, 1000% 100%, 0 100%)',
            fontSize: '3rem',
            flex: '1 0 0'
          }}
        >
          <motion.div
            sx={{ display: 'inline-block' }}
            variants={logoVariants.textR}
          >
            PWN
          </motion.div>
        </Heading>
      </Box>
    </motion.div>
  )
}

Logo.propTypes = {
  animate: PropTypes.bool
}

export default React.memo(Logo)
